import * as React from 'react';
import styled from 'styled-components';
import {StaticImage} from 'gatsby-plugin-image';

import Layout from '../components/layout';
import {ContainerLg, ExternalLinkButton, FientaLinkButton} from '../components/components';
import {breakpointsMediaQueries} from '../style/my-theme';
import {Script} from 'gatsby';

const StyledTicketsContainer = styled.div.attrs({
  className: 'tickets-container'
})`
  margin: 2rem 0;
  .container-lg {
    h1,
    h2 {
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      margin: 0;
    }
    h1 {
      margin-bottom: 2rem;
    }
    .img-mobile {
      display: none;
    }
    ${breakpointsMediaQueries.md} {
      .img-desktop {
        display: none;
      }
      .img-mobile {
        display: initial;
      }
    }
  }
`;

const TicketsPage = () => {
  return (
    <Layout title="Tickets - Armada JS 2024">
      <StyledTicketsContainer>
        <ContainerLg>
          <h1>TICKET SALES STARTS SOON!</h1>
          {/* <h2>We are completely sold out for 2022!</h2> */}

          {/* <StaticImage
            className="img-mobile"
            height={800}
            objectFit="contain"
            src="../images/tickets/tickets-prices-mobile.svg"
            alt="Armada JS Tickets 2023"
          />
          <img
            className="img-desktop"
            style={{maxHeight: '800px', objectFit: 'contain'}}
            src="/images/tickets-prices.svg"
          />

          <FientaLinkButton
            label="BUY NOW"
            url="https://fienta.com/armada-js-2023"
          ></FientaLinkButton> */}

          <div>
            <h3>Ticket includes:</h3>
            <ol>
              <li>Access to all conference sections</li>
              <li>Two days of lectures</li>
              <li>Coffee breaks with free drinks</li>
              <li>Lunch on both days</li>
              <li>Access to the expo section</li>
              <li>Access to the official conference party</li>
              <li>Awesome time!</li>
            </ol>
          </div>
          <div>
            <h3>For companies / Za kompanije:</h3>
            <p>
              We can provide an invoice for the tickets that can be payed directly to our account.
              Reach out to: <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
            </p>
            <p>
              Možemo da izdamo fakturu za željeni broj ulaznica koja se može platiti direktno preko
              računa. Mi smo u sistemu PDV-a. Za više informacija:{' '}
              <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
            </p>
          </div>
          <h2>Subscribe to our e-mail list to get the latest info</h2>
          <ExternalLinkButton
            label="SUBSCRIBE NOW"
            url="http://eepurl.com/gnVWPH"
          ></ExternalLinkButton>
        </ContainerLg>
      </StyledTicketsContainer>
    </Layout>
  );
};
export default TicketsPage;
